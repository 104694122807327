// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

require('slick-carousel');
require('bootstrap');
require('../scripts/menu');
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import '../stylesheets/application.scss';

document.addEventListener('turbolinks:load', function () {
  $(function () {
    // $('[data-toggle="tooltip"]').tooltip();
    // $('[data-toggle="popover"]').popover();
  });
  //Sliders
  //Simple slider
  $('.lazy').slick({
    refresh: true,
    centerMode: false,
    lazyLoad: 'ondemand', // ondemand progressive anticipated
    infinite: true,
    arrows: true,
    prevArrow: '<a id="left-arrow"><i class="fas fa-chevron-left nav-arrow"></i></a>',
    nextArrow: '<a id="right-arrow"><i class="fas fa-chevron-right nav-arrow"></i></a>',
  });
  //Slider 3 elements
  $('.slide-lazy').slick({
    slidesToShow: 3,
    refresh: true,
    centerMode: true,
    infinite: true,
    arrows: true,
    prevArrow: '<a id="left-arrow"><i class="fas fa-chevron-left nav-arrow"></i></a>',
    nextArrow: '<a id="right-arrow"><i class="fas fa-chevron-right nav-arrow"></i></a>',
  });
});
